
import { defineComponent } from "vue";

export default defineComponent({
  name: "KtWidget5",
  components: {},
  props: {
    widgetClasses: {type: String, default: ""},
  },
});
